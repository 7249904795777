#videoBackground {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}

.video {
  object-fit: cover;
}