.admin-panel {
    width: 80%;
    margin: 0 auto 20px 5vw; /* Добавляем отступ сверху */
    padding-top: 110px;
    font-family: Arial, sans-serif;
}

.profile-section {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: space-between;
}

.profile-section img {
    width: 100px; /* Увеличиваем размер фотографии */
    height: 100px;
    border-radius: 50%;
    margin-right: 20px; /* Увеличиваем отступ справа */
}

.profile-section .name {
    font-size: 1.2em;
}

.filters {
    margin-bottom: 20px;
}

.filters input[type="text"],
.filters select {
    margin-right: 16px;
    padding: 8px;
    border: 1px solid #c59a69;
    border-radius: 5px;
}

input{
    background-color: #D2AE84;
}
select {
    background-color: #D2AE84;
    height: 39px;
}

.filters button {
    padding: 8px 16px;
    margin-right: 8px;
    background-color: #01522b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 39px;
}

.filters button:hover {
    background-color: #026b38;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th, table td {
    border: 1px solid #00150b;
    padding: 10px;
    text-align: left;
}

table th {
    background-color: #D2AE84;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ffffff;
}

table tr td:last-child {
    text-align: right;
}

table tr th:last-child {
    text-align: right;
}

span{
    cursor: pointer;
}


.pagination {
    margin-top: 20px;
}

.pagination button {
    padding: 8px 16px;
    background-color: #01522b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 39px;
}

.pagination button:disabled {
    background-color: #3c5046;
    cursor: not-allowed;
}

.pagination button:hover {
    background-color: #026b38;
}

.pagination span {
    margin-right: 16px;
    margin-left: 16px;
}

body {
    background-color: #00150b;
}

.name {
    color: #ccc;
}

.pageSpan{
    color: #ccc;
}

td  {
    background-color: #dab892;
}

.modal-title {
    color: #dddddd;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-top: 0;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
}

.input-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 columns */
    gap: 32px; /* Add space between columns */
    margin-bottom: 16px;
}


.input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #dab892; /* Use the specified color */
    border-radius: 5px;
    outline: none;
    background-color: #d2ae84; /* Use the specified color */
}

.save-button {
    background-color: #01522b; /* Use the specified color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
}

.save-button:hover {
    background-color: #026b38; /* Use the specified color */
}

.switch-button {
    padding: 8px 16px;
    background-color: rgb(1, 82, 43);
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 39px;
}

.switch-button:hover {
    background-color: #026b38;
}


ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.tg-list {
    text-align: center;
    display: flex;
    align-items: center;
}

.tg-list-item {
    margin: 0 2em;
}

.tgl {
    display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
    box-sizing: border-box;
}
.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl + .tgl-btn::-moz-selection {
    background: none;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
    background: none;
}
.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}
.tgl + .tgl-btn:after {
    left: 0;
}
.tgl + .tgl-btn:before {
    display: none;
}
.tgl:checked + .tgl-btn:after {
    left: 50%;
}

.tgl-light + .tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
}
.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
}
.tgl-light:checked + .tgl-btn {
    background: #9FD6AE;
}
