.modal-title {
    color: #dddddd;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
}


.input-field {
    width: 100%;
    height: 24px;
    padding: 8px;
    border: 1px solid #dab892; /* Use the specified color */
    border-radius: 5px;
    outline: none;
    background-color: #d2ae84; /* Use the specified color */
    margin: 8px;
}

.save-button {
    background-color: #01522b; /* Use the specified color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 80%;
}

.save-button:hover {
    background-color: #026b38; /* Use the specified color */
}