.outer-quiz-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.quiz-result-container {
    max-width: 800px;
    min-width: 800px;
    margin:auto;
    margin-bottom: 35vh !important;
    padding: 35px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    backdrop-filter: blur(10px);
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: center;
}

