body {
  margin: 0;
  font-family: 'Fortuna', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Fortuna', sans-serif;
}


body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #D2AE84;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #2c2c2c;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #D2AE84;  /* creates padding around scroll thumb */
}