/* helpers/align.css */

.align {
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-items: center;
    place-items: center;
}

/* helpers/grid.css */

:root {
    --gridMaxWidth: 28em;
    --gridWidth: 90%;
}

.grid {
    width: 90%;
    width: var(--gridWidth);
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    max-width: var(--gridMaxWidth);
    height: 100vh;
    display:flex;
    align-items: center;
}

/* helpers/icon.css */

.icon {
    height: 1.25em;
    display: inline-block;
    width: 1.25em;
    line-height: 1.25em;
    margin-right: 0.625em;
    text-align: center;
    vertical-align: middle;
}

.icon--info {
    background-color: #e5e5e5;
    border-radius: 50%;
}

/* layout/base.css */

:root {
    --bodyBackgroundColor: #00150B;
    --bodyColor: #999;
    --bodyFontFamily: 'Helvetica', 'Arial';
    --bodyFontFamilyFallback: sans-serif;
    --bodyFontSize: 0.875rem;
    --bodyFontWeight: 400;
    --bodyLineHeight: 1.5;
}


html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background-color: #eaeaea;
    background-color: var(--bodyBackgroundColor);
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-family: var(--bodyFontFamily), var(--bodyFontFamilyFallback);
    font-size: 0.875rem;
    font-size: var(--bodyFontSize);
    font-weight: 400;
    font-weight: var(--bodyFontWeight);
    line-height: 1.5;
    line-height: var(--bodyLineHeight);
    margin: 0;
    min-height: 100vh;
}

/* modules/anchor.css */

:root {
    --anchorColor: inherit;
    --anchorHoverColor: #01723c;
}

a {
    color: inherit;
    color: var(--anchorColor);
    text-decoration: none;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
}

a:hover {
    color: #1dabb8;
    color: var(--anchorHoverColor);
}

/* modules/form.css */

fieldset {
    border: none;
    margin: 0;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    font: inherit;
    margin: 0;
    outline: none;
    padding: 0;
}

input[type='submit'] {
    cursor: pointer;
}

form{
    width: 100%;

}

.form input[type='text'],
.form input[type='password'] {
    width: 88%;
}

/* modules/login.css */

:root {
    --loginBorderRadius: 0.25em;
    --loginHeaderBackgroundColor: #01522b;
    --loginInputBorderRadius: 0.25em;
}

.login__header {
    background-color: #282830;
    background-color: var(--loginHeaderBackgroundColor);
    border-top-left-radius: 0.25em;
    border-top-left-radius: var(--loginBorderRadius);
    border-top-right-radius: 0.25em;
    border-top-right-radius: var(--loginBorderRadius);
    color: #fff;
    padding: 1.5em;
    text-align: center;
    text-transform: uppercase;
}

.login__title {
    font-size: 1rem;
    margin: 0;
}

.login__body {
    background-color: #017f42;
    padding-top: 1.5em !important;
    padding-bottom: 2em !important;
    padding: 3em;
    position: relative;
}

.login__body::before {
    background-color: #017f42;
    content: '';
    height: 0.5em;
    left: 50%;
    margin-left: -0.25em;
    margin-top: -0.25em;
    position: absolute;
    top: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 0.5em;
}

.login input[type='text'],
.login input[type='password'] {
    border: 0.0625em solid #017f42;
    padding: 1em 1.25em;
}

.login input[type='text'] {
    border-top-left-radius: 0.25em;
    border-top-left-radius: var(--loginInputBorderRadius);
    border-top-right-radius: 0.25em;
    border-top-right-radius: var(--loginInputBorderRadius);
}

.login input[type='password'] {
    border-bottom-left-radius: 0.25em;
    border-bottom-left-radius: var(--loginInputBorderRadius);
    border-bottom-right-radius: 0.25em;
    border-bottom-right-radius: var(--loginInputBorderRadius);
    border-top: 0;
}

.login input[type='submit'] {
    background-color: #01522b;
    border-radius: 0.25em;
    border-radius: var(--loginInputBorderRadius);
    color: #fff;
    font-weight: 700;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding: 0.75em 1.25em;
    -webkit-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.login input[type='submit']:focus,
.login input[type='submit']:hover {
    background-color: #01723c;
}

.login__footer {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #017f42;
    border-bottom-left-radius: 0.25em;
    border-bottom-left-radius: var(--loginBorderRadius);
    border-bottom-right-radius: 0.25em;
    border-bottom-right-radius: var(--loginBorderRadius);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 3em;
    padding-left: 3em;
    padding-right: 3em;
}

.login__footer p {
    margin: 0;
}
