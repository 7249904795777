.search-box,.close-icon {
    position: relative;
    padding: 10px 10px 10px 10px;
}

.search-wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
    position: relative;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.search-box {
    width: 96%;
    border: 1px solid #ccc;
    outline: 0;
    background-color: #ffe2c1;
    border-radius: 15px;
}
.close-icon {
    display: none;
    border:1px solid transparent;
    background-color: transparent;
    vertical-align: middle;
    outline: 0;
    cursor: pointer;
}
.close-icon:after {
    content: "X";
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    background-color: #FA9595;
    z-index:1;
    right: 35px;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 2px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 12px;
    box-shadow: 0 0 2px #E50F0F;
    cursor: pointer;
}
.search-box:not(:valid) ~ .close-icon {
    display: none;
}

.button-container {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.start_test_button {
    display: flex;
    overflow: hidden;
    margin: 10px 10px 10px 0px;
    padding: 12px 12px;
    cursor: pointer;
    user-select: none;
    transition: all 150ms linear;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;
    text-transform: none;

    color: #fff;
    border: 0 none;
    border-radius: 12px;

    background-color: #01522b;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;

    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;

    justify-content: center;
    align-items: center;
    flex: 0 0 200px;

    /*box-shadow: 2px 5px 10px var(--color-smoke);*/

    &:hover {
        transition: all 150ms linear;

        opacity: .85;
    }

    &:active {
        transition: all 150ms linear;
        opacity: .75;
    }

    &:focus {
        outline: 1px dotted #959595;
        outline-offset: -4px;
    }
}

.button {
       display: flex;
       overflow: hidden;
       margin: 10px;
       padding: 12px 12px;
       cursor: pointer;
       user-select: none;
       transition: all 150ms linear;
       text-align: center;
       white-space: nowrap;
       text-decoration: none !important;
       text-transform: none;

       color: #fff;
       border: 0 none;
       border-radius: var(--borderRadius);

       font-size: 14px;
       font-weight: 500;
       line-height: 1.3;

       -webkit-appearance: none;
       -moz-appearance:    none;
       appearance:         none;

       justify-content: center;
       align-items: center;
       flex: 0 0 200px;

       /*box-shadow: 2px 5px 10px var(--color-smoke);*/

       &:hover {
           transition: all 150ms linear;

           opacity: .85;
       }

       &:active {
           transition: all 150ms linear;
           opacity: .75;
       }

       &:focus {
           outline: 1px dotted #959595;
           outline-offset: -4px;
       }
   }

.button.-regular {
    color: var(--color-snow);
    background-color: #01522b;
    margin: 0;

    &:hover {
        color: #202129;
        background-color: #018a48;
        opacity: 1;
    }

    &:active {
        background-color: #d5d6d6;
        opacity: 1;
    }
}

.button.-dark {
    color: var(--color-snow);
    background: var(--color-dark);
    margin: 0;

    &:hover {
        color: #202129;
        background-color: #018a48;
        opacity: 1;
    }

    &:focus {
        outline: 1px dotted white;
        outline-offset: -4px;
    }
}

p {
    font-size: 24px;
}

.dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;

    font-size: 16px;
    color: white;
}

:root {
       --color-dark:  #01522b;
       --color-ocean:  #416dea;
       --color-grass: #3dd28d;
       --color-snow: #FFFFFF;
       --color-salmon: #F32C52;
       --color-sun: #feee7d;
       --color-alge: #7999a9;
       --color-flower: #353866;
       --color-smoke: #e4e4e4;

       --borderRadius: 10px;

       --font-face: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
   }