.glossary-page h2{
  color: white;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.glossary-page h3{
  color: white;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0 20px 0;
}

.glossary-page p{
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.glossary-page span{
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.glossary-page div{
  color: white;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
