.outer-quiz-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.quiz-container {
    max-width: 800px;
    min-width: 800px;
    margin: auto;
    margin-bottom: 35vh !important;
    padding: 35px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    vertical-align: middle;
}

.question {
    margin-bottom: 20px;
    font-weight: bold;
    color: #ffffffd4;
}

.answers-form {
    display: flex;
    flex-direction: column;
}

/* Custom styling for radio buttons */
input[type="radio"],
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid #ffffffd4;; /* Black stroked border */
    border-radius: 4px; /* Squared shape */
    outline: none;
    margin-right: 10px;
    position: relative; /* Ensure position is relative for pseudo-element */
}

/* Inner tick mark for radio buttons when selected */
input[type="radio"]:checked::before,
input[type="checkbox"]:checked::before {
    content: '\2713'; /* Unicode character for tick mark */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #000000; /* Color of the tick mark */
}

/* Style for label text */
.answer-option {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative; /* Make the container relative for the pseudo-element */
}

/* Style for label text */
label {
    color: #ffffffd4;
    margin-left: 5px;
    flex-grow: 1;
    width: calc(100% - 30px); /* Set a fixed width for the label container */
    word-wrap: break-word; /* Allow long text to wrap */
    font-size: 17px;
}
/* Style for submit button */
.submit-button {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #026435;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
    font-size: 18px;
}

.submit-button:hover {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #04743d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

.progress {
    color: white;
    text-align: right;
    margin-top: 20px;
}

.loading {
    text-align: center;
    font-size: 20px;
    margin-top: 50px;
}
