.contents-inner-page h2{
    color: white;
    font-size: 58px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.contents-inner-page h3{
    color: white;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 10px 0 20px 0;
}

.contents-inner-page p{
    color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contents-inner-page span{
    color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contents-inner-page div{
    color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content-body {
    height: 100%;
    padding: 32px 10% 128px 10%;

    a {
        color: #027ea4;
    }

    a:hover {
        color: deepskyblue;
        cursor: pointer;
    }
}

.content-title {
    color: white;
    font-size: 48px;
    font-style: normal;
    line-height: normal;
    margin-bottom: 20px;
    padding: 64px 64px 0 64px;
}

.contents-inner-page img{
    max-width: 75vw;
    max-height: 75vh;
    width: 100%  !important;
    height: 100% !important;
    object-fit: contain;
    background-color: rgb(236, 236, 236);
}




