.navigationFrame {
    position: fixed;
}

svg:not(.MuiSvgIcon-root){
    position: fixed;
    z-index: 2;
}

.navigation-button-svg:hover .navigation-button{
    fill: #a78968;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}


